import React from 'react'

import Layout from '../components/Layout'
import '../styles/styles.scss'

const AccountDeletionPage = () => {
  const title = 'Bergamot - Account deletion'
  const description =
    "Sauvegardez vos recettes depuis n'importe quel site internet, consultez les depuis n'importe où !"
  return (
    <Layout title={title} description={description}>
      <main>
        <section className="section section--terms">
          <div className="container intro content">
            <h2>Account Deletion</h2>
            <p>
              If you want to delete your account, please send an email to "hello@bergamot.app".
            </p>
            <p>
              Your account, and all the information associated with it, will be deleted within 30
              days.
            </p>
          </div>
        </section>
      </main>
    </Layout>
  )
}
export default AccountDeletionPage
